import facebook from "./facebook.svg";
import instagram from "./instagram.svg";
import x from "./x.svg";
import tiktok from "./tiktok.svg";

export default {
  facebook,
  instagram,
  x,
  tiktok
};
