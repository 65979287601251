<template>
  <div
    v-show="show"
    class="absolute z-10 inset-0 overflow-y-auto transition duration-300 ease-in-out"
    :class="{
      backdrop: show,
      'pointer-events-none': !show
    }"
  >
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="absolute inset-0" :aria-hidden="show">
        <div class="absolute inset-0" @click.self="hide"></div>
      </div>
      <span
        class="sm:inline-block sm:align-middle sm:h-screen"
        :aria-hidden="show"
        >&#8203;
      </span>
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-x-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-white">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const rootSelector = "#browser-card";

export default {
  name: "BModal",

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    show(newVal) {
      if (newVal === true) {
        document.querySelector(rootSelector).classList.add("overflow-hidden");
      } else {
        document
          .querySelector(rootSelector)
          .classList.remove("overflow-hidden");
      }
    }
  },

  methods: {
    hide() {
      this.$emit("update:show", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.backdrop {
  background-color: rgba(20, 20, 20, 0.5);
}
</style>
