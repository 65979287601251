<template>
  <div>
    <text-editor v-model="topContent" />
  </div>
</template>

<script>
import TextEditor from "@/components/TextEditor";
import * as mutation from "@/store/modules/builder/mutation-types";

export default {
  name: "TopDescription",

  components: {
    TextEditor
  },

  computed: {
    topContent: {
      get() {
        return this.$store.state.builder.listItemConfig?.topContent;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_TOP_CONTENT}`,
          val
        );
      }
    }
  }
};
</script>
