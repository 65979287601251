<template>
  <div
    class="rounded px-4 py-2"
    :class="{ 'bg-yellow-300 text-yellow-900': type === 'warn' }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "BAlert",

  props: {
    type: {
      type: String,
      default: "warn",
      validator: val => ["warn"].indexOf(val) !== -1
    }
  }
};
</script>
