<template>
  <v-swatches
    class="ml-4"
    show-fallback
    fallback-input-type="color"
    popover-x="left"
    :swatch-style="{ borderRadius: '4px' }"
    :trigger-style="{
      width: '32px',
      height: '32px',
      borderRadius: '4px'
    }"
    :value="value"
    @input="changeValue"
  ></v-swatches>
</template>

<script>
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import _debounce from "lodash-es/debounce";

export default {
  name: "ColorPicker",

  components: {
    VSwatches
  },

  props: {
    value: {
      type: String,
      default: ""
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    changeValue(value) {
      if (this.lazy) {
        this.debounceChange(value);
      } else {
        this.$emit("input", value);
      }
    },
    debounceChange: _debounce(function(value) {
      this.$emit("input", value);
    }, 100)
  }
};
</script>

<style lang="scss">
.vue-swatches__trigger {
  border: 1px solid #ccc;
}

.vue-swatches__fallback__input {
  padding: 0px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
