<template>
  <label
    class="inline-flex items-center mt-2"
    :disabled="disabled"
    @click="focus"
    @keydown.prevent.enter="$refs.label.click()"
  >
    <input
      ref="input"
      v-model="computedValue"
      type="radio"
      :disabled="disabled"
      :required="required"
      :name="name"
      :value="nativeValue"
      @click.stop="emitClick"
    /><span class="ml-2">
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: "BRadio",

  props: {
    value: {
      type: [String, Number, Boolean, Function, Object, Array],
      default: ""
    },
    nativeValue: {
      type: [String, Number, Boolean, Function, Object, Array],
      default: ""
    },
    type: { type: String, default: "" },
    disabled: Boolean,
    required: Boolean,
    name: { type: String, default: "" },
    size: { type: String, default: "" }
  },

  data() {
    return {
      newValue: this.value
    };
  },

  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit("input", value);
      }
    }
  },

  watch: {
    /**
     * When v-model change, set internal value.
     */
    value(value) {
      this.newValue = value;
    }
  },

  methods: {
    focus() {
      // MacOS FireFox and Safari do not focus when clicked
      this.$refs.input.focus();
    },
    emitClick() {
      this.$emit("select");
    }
  }
};
</script>
