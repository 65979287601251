<template>
  <div class="bg-white shadow rounded-full py-2 px-4 flex items-center">
    <b-icon name="lock" class="mr-2" />
    {{ $t("pro_feature_badge.pro_feature") }}
  </div>
</template>

<script>
import BIcon from "@/components/BIcon";

export default {
  name: "ProFeatureBadge",

  components: {
    BIcon
  }
};
</script>
