<template>
  <div class="border rounded-lg p-2 mb-3">
    <div class="flex mb-3 justify-end">
      <b-tooltip label="Delete variant" position="is-left">
        <div
          role="button"
          class="flex justify-center items-center bg-gray-50 hover:bg-gray-100 rounded w-6 h-6"
          @click="deleteOption"
        >
          <b-icon name="times" size="sm" />
        </div>
      </b-tooltip>
    </div>
    <b-field :type="nameValidation.state" :message="nameValidation.message">
      <b-input
        :value="option.name"
        expanded
        :placeholder="$t('variants_option.variant_name')"
        @input="updateOption({ ...option, name: $event })"
      ></b-input>
    </b-field>
    <div class="my-3 flex items-center">
      <b-dropdown
        :value="option.type"
        aria-role="list"
        @input="updateOption({ ...option, type: $event })"
      >
        <template #trigger="{ active }">
          <b-button
            :label="
              option.type ? `Type: ${variantTypes.get[option.type]}` : 'Type'
            "
            :icon-right="active ? 'caret-up' : 'caret-down'"
          />
        </template>

        <b-dropdown-item
          v-for="(variantType, variantIndex) in variantTypes.options"
          :key="`variant-option-${variantIndex}`"
          :value="variantType.value"
          aria-role="listitem"
        >
          {{ variantType.text }}
        </b-dropdown-item>
      </b-dropdown>
      <b-checkbox
        :value="option.required"
        class="ml-3"
        @input="updateOption({ ...option, required: $event })"
      >
        {{ $t("variants_option.required") }}
      </b-checkbox>
    </div>
    <b-taginput
      :value="option.options"
      :data="fieldsFiltered"
      autocomplete
      open-on-focus
      field="name"
      :placeholder="$t('variants_option.variant_fields')"
      @input="updateOption({ ...option, options: $event })"
    ></b-taginput>
  </div>
</template>

<script>
import BIcon from "@/components/BIcon";
import VariantTypes from "@/constants/checkout/variant-type";
import * as mutations from "@/store/modules/builder/mutation-types";

export default {
  name: "VariantsOption",

  components: {
    BIcon
  },

  props: {
    index: {
      type: Number,
      required: true
    },
    option: {
      type: Object,
      required: true
    }
  },

  computed: {
    fields() {
      return this.$store.state.builder.fields;
    },
    fieldsFiltered() {
      return this.fields.filter(
        f => !this.option.options.find(cf => cf.name === f.name)
      );
    },
    variantTypes() {
      return VariantTypes;
    },
    nameValidation() {
      const value = this.option.name;
      let message = "";
      let state = "";

      if (!value.length) {
        message = "The name is required";
        state = "is-danger";
      }

      return { message, state };
    }
  },

  methods: {
    updateOption(option) {
      this.$store.commit(`builder/${mutations.UPDATE_PRODUCT_OPTION}`, {
        index: this.index,
        option: option
      });
    },
    deleteOption() {
      this.$store.commit(
        `builder/${mutations.DELETE_PRODUCT_OPTION}`,
        this.index
      );
    }
  }
};
</script>
