<template>
  <div>
    <div class="flex justify-between px-2 sm:px-4 pb-4">
      <div class="flex">
        <div
          v-show="item.image"
          class="h-16 w-16 rounded-lg overflow-hidden bg-gray-100"
        >
          <img v-lazy="item.image" class="object-cover h-full w-full inset-0" />
        </div>
        <div
          v-show="!item.image"
          class="h-16 w-16 rounded-lg overflow-hidden bg-gray-100 flex items-center justify-center"
        >
          <div>
            <b-icon
              name="shopping-cart"
              class="text-gray-300 block"
              size="lg"
            />
          </div>
        </div>
        <div class="px-2 sm:px-4 flex flex-col justify-between">
          <p class="overflow-ellipsis font-semibold overflow-hidden">
            {{ itemName }}
          </p>
          <div>
            {{ formattedPrice }}
          </div>
        </div>
      </div>
      <div class="flex flex-col items-end justify-between">
        <div role="button" class="px-3" @click="removeFromCart">
          <b-icon name="times" size="xs" />
        </div>
        <div class="flex items-center">
          <button
            class="text-center px-2 py-0 border border-gray-300 rounded-full shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none w-8 h-8"
            @click.stop="changeItemAmount(-1)"
          >
            <b-icon name="minus" size="sm" />
          </button>
          <div class="mx-2 text-sm">
            {{ item.amount }}
          </div>
          <button
            class="text-center px-2 py-0 border border-gray-300 rounded-full shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none w-8 h-8"
            @click.stop="changeItemAmount(1)"
          >
            <b-icon name="plus" size="sm" />
          </button>
        </div>
      </div>
    </div>
    <div v-if="parsedVariants.length" class="px-2 pb-4 sm:px-4">
      <div class="px-4 py-2 rounded-md border">
        <div class="flex justify-between items-center">
          <div class="text-sm font-bold">Options</div>
          <div
            role="button"
            class="text-sm"
            @click="showOptions = !showOptions"
          >
            <b-icon name="list-ul" />
          </div>
        </div>
        <div
          v-for="(option, index) in parsedVariants"
          v-show="showOptions"
          :key="`option-${index}`"
          class="text-sm"
        >
          {{ option.name }} - {{ option.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BIcon from "../BIcon";
import VariantTypes from "@/constants/checkout/variant-type";
import money from "@/utils/money";
import StringUtils from "@/utils/string";
import currency from "currency.js";

export default {
  name: "ShoppingCartItem",

  components: {
    BIcon
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      showOptions: false
    };
  },

  computed: {
    price() {
      return currency(this.item.price).multiply(this.item.amount);
    },
    formattedPrice() {
      return money.format(this.price);
    },
    parsedVariants() {
      const variants = this.item?.variants || [];
      const parsedVariants = [];

      variants.forEach(variant => {
        if (
          variant.type === VariantTypes.NUM_SINGLE_CHOICE &&
          variant.selected
        ) {
          parsedVariants.push({
            name: variant.selected.name,
            value: variant.selected.value
          });
        } else if (
          variant.type === VariantTypes.NUM_MULTIPLE_CHOICE &&
          variant.selected.length
        ) {
          variant.selected.forEach(multChoice => {
            parsedVariants.push({
              name: multChoice.name,
              value: multChoice.value
            });
          });
        }
      });
      return parsedVariants;
    },
    itemName() {
      return StringUtils.truncate(this.item.name, 23);
    }
  },

  methods: {
    changeItemAmount(amountChange) {
      this.$store.dispatch("checkout/changeItemAmount", {
        item: this.item,
        amountChange
      });
    },
    removeFromCart() {
      this.$store.dispatch("checkout/removeFromCart", this.item);
    }
  }
};
</script>
