<template>
  <div>
    <div class="flex justify-between">
      <b-field :label="$t('builder.activate')">
        <b-checkbox v-model="active">
          {{ $t("builder.active") }}
        </b-checkbox>
      </b-field>
      <a
        class="text-xs text-blue-500 hover:text-blue-600 hover:underline"
        href="https://www.notion.so/Progressive-web-app-b03a0282497346ba9f651d67e3b314bd"
        target="_blank"
      >
        {{ $t("pwa_config.learn_more") }}
        <b-icon name="external-link-alt" class="ml-1" />
      </a>
    </div>
    <b-field :label="$t('builder.name')">
      <b-input v-model="name" expanded></b-input>
    </b-field>
    <b-field :label="$t('builder.color')">
      <div class="flex">
        <b-input v-model="color"></b-input>
        <color-picker v-model="color" />
      </div>
    </b-field>
    <b-field
      :label="$t('builder.image')"
      :type="imageFieldType"
      :message="$t('pwa_config.learn_more')"
    >
      <div class="flex">
        <b-upload
          v-model="imageFile"
          expanded
          accept=".png"
          class="text-red"
          :loading="uploadingImage"
          :disabled="uploadingImage"
        >
          <a class="button is-fullwidth">
            <b-icon name="upload" class="mr-3"></b-icon>
            <span v-if="uploadingImage">{{ $t("builder.uploading") }}</span>
            <span v-else> {{ $t("builder.upload_pwa_image") }} </span>
          </a>
        </b-upload>
        <div
          class="w-11 p-1 border rounded ml-3 flex justify-center items-center"
        >
          <div>
            <img :src="icon" />
          </div>
        </div>
      </div>
    </b-field>
    <div v-if="icon">
      <div class="font-bold">
        {{ $t("builder.splash_sreen_preview") }}
      </div>
      <div
        class="border rounded w-60 h-96 mx-auto mt-2 flex justify-center items-center"
        :style="{ backgroundColor: color }"
      >
        <div>
          <img :src="icon" class="w-24" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BIcon from "@/components/BIcon";
import ColorPicker from "@/components/ColorPicker";
import * as mutation from "@/store/modules/builder/mutation-types";
import { fileSize } from "@/utils/file";

export default {
  name: "PwaConfig",

  components: {
    BIcon,
    ColorPicker
  },

  data() {
    return {
      imageFile: null,
      uploadingImage: false
    };
  },

  computed: {
    active: {
      get() {
        return this.$store.state.builder.pwa.active;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_PWA_ACTIVE}`, val);
      }
    },
    color: {
      get() {
        return this.$store.state.builder.pwa.color;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_PWA_COLOR}`, val);
      }
    },
    name: {
      get() {
        return this.$store.state.builder.pwa.name;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_PWA_NAME}`, val);
      }
    },
    icon: {
      get() {
        return this.$store.state.builder.pwa.icon;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_PWA_ICON}`, val);
      }
    },
    invalidImage() {
      if (!this.imageFile) return false;
      const size = fileSize(this.imageFile);
      return size > 2;
    },
    imageFieldType() {
      if (this.invalidImage) {
        return "is-danger";
      }
      return "";
    }
  },

  watch: {
    imageFile(val) {
      if (!this.invalidImage) {
        this.uploadingImage = true;
        this.$store.dispatch("builder/updloadPwaImage", val).finally(() => {
          this.uploadingImage = false;
        });
      }
    }
  }
};
</script>
