<template>
  <div>
    <b-field :label="$t('builder.navigation_color')">
      <div class="flex">
        <b-input v-model="navbarColor"></b-input>
        <color-picker v-model="navbarColor" />
      </div>
    </b-field>
    <b-field :label="$t('builder.background_color')">
      <div class="flex">
        <b-input v-model="backgroundColor"></b-input>
        <color-picker v-model="backgroundColor" />
      </div>
    </b-field>
    <b-field :label="$t('builder.font')" class="mt-4">
      <b-select
        v-model="fontSelected"
        :placeholder="$t('builder.font')"
        :disabled="!isPro"
        expanded
        icon="grip-lines"
      >
        <option :value="{}">default</option>
        <option
          v-for="font in fonts"
          :key="`caption-field-${font.name}`"
          :value="font"
          :style="{ fontFamily: font.name }"
        >
          <span>{{ font.name }}</span>
        </option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
import ColorPicker from "@/components/ColorPicker";
import db from "@/plugins/firebase/db";
import * as mutation from "@/store/modules/builder/mutation-types";

export default {
  name: "ThemeConfig",

  components: {
    ColorPicker
  },

  data() {
    return {};
  },

  computed: {
    isPro() {
      return this.$store.state.builder?.isPro;
    },
    backgroundColor: {
      get() {
        return this.$store.state.builder.theme.backgroundColor;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_THEME_BACKGROUND_COLOR}`,
          val
        );
      }
    },
    navbarColor: {
      get() {
        return this.$store.state.builder.theme.navbarColor;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_THEME_HEADER_COLOR}`, val);
      }
    },
    fonts: {
      get() {
        return this.$store.state.builder.fonts;
      },
      set(val) {
        this.$store.commit("builder/SET_FONTS", val);
      }
    },
    fontSelected: {
      get() {
        return this.$store.state.builder.theme.font || {};
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_THEME_FONT}`, val || {});
        if (val && val.cdn)
          document.getElementById("preview").style.fontFamily = `'${val.name}'`;
        else document.getElementById("preview").removeAttribute("style");
      }
    }
  },
  mounted() {
    const fonts = [];
    if (this.fonts.length === 0)
      db.collection("fonts").onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = { ...doc.data() };
          fonts.push(data);
          const fileref = document.createElement("link");
          fileref.rel = "stylesheet";
          fileref.type = "text/css";
          fileref.href = data.cdn;
          document.getElementsByTagName("head")[0].appendChild(fileref);
        });
        this.fonts = fonts;
      });
  }
};
</script>
