<template>
  <div>
    <b-field :label="$t('builder_integrations.embed_widget')">
      <b-input
        :placeholder="widgetCode"
        :disabled="!isPro"
        :value="isPro ? widgetCode : ''"
        type="textarea"
      ></b-input>
    </b-field>
    <b-field
      :label="$t('builder_integrations.google_analytics_ID')"
      class="mt-4"
    >
      <b-input
        v-model="gaId"
        :placeholder="$t('builder_integrations.ga_measurement_ID')"
        :disabled="!isPro"
      ></b-input>
    </b-field>
    <b-button
      v-if="!isPro"
      type="is-primary"
      icon-left="lock"
      @click="upgradeToPro"
    >
      {{ $t("builder_integrations.set_google_analytics") }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: "BuilderIntegrations",

  data() {
    return {
      gaIdChanged: false,
      whiteListWebsites: []
    };
  },

  computed: {
    gaId: {
      get() {
        return this.$store.state.builder.gaId;
      },
      set(val) {
        this.$store.commit("builder/SET_GA_ID", val);
      }
    },
    isPro() {
      return this.$store.getters["builder/isPro"];
    },
    websiteId() {
      return this.$store.state.builder.websiteId;
    },
    userId() {
      return this.$store.state.user.uid;
    },
    widgetCode() {
      return ` <!-- This goes between the <head> tags --> <link href="https://widget.getbiew.com/css/app.css" rel="stylesheet"/> <!-- This goes between the <body> tags  --> <biew-widget id="${
        this.websiteId
      }" uid="${
        this.userId
      }"></biew-widget> <!-- This goes before the closing body tag </body> --> <script src="https://widget.getbiew.com/js/app.js"></${"script"}>`;
    }
  },

  watch: {
    gaId() {
      this.gaIdChanged = true;
    }
  },

  methods: {
    upgradeToPro() {
      this.$emit("open-payment-modal");
    }
  }
};
</script>
