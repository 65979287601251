<template>
  <div>
    <b-alert v-show="!hasSlugField" type="warn" class="mb-4">
      {{ $t("builder_detail_config.you_need")
      }}<b> {{ $t("builder_detail_config.slug") }} </b>
      {{ $t("builder_detail_config.header_for") }}
      <a
        href="https://docs.google.com/spreadsheets/d/1kEcZrvpe2hsTK4Ic-mITi9SGn2cnwe0KjYyvu9zDyaA/edit#gid=0"
        target="_blank"
        class="underline"
      >
        {{ $t("builder_detail_config.view_example") }}
      </a>
    </b-alert>
    <div>
      <div class="field">
        <b-checkbox v-model="hideSinglePageView">
          {{ $t("builder_detail_config.disable_view") }}
        </b-checkbox>
      </div>
      <b-field :label="$t('builder_detail_config.image')">
        <b-taginput
          v-model="imageFields"
          :data="imageFieldsFiltered"
          autocomplete
          open-on-focus
          field="name"
          :placeholder="$t('builder_detail_config.add_image')"
        >
        </b-taginput>
      </b-field>
      <b-field :label="$t('builder_detail_config.title')" class="mt-4">
        <b-select
          v-model="titleField"
          :placeholder="$t('builder_detail_config.title_name')"
          expanded
          icon="heading"
        >
          <option
            v-for="field in fields"
            :key="`title-field-${field.name}`"
            :value="field"
          >
            {{ field.name }}
          </option>
        </b-select>
      </b-field>
      <b-field :label="$t('builder_detail_config.description')" class="mt-4">
        <b-select
          v-model="descriptionField"
          :placeholder="$t('builder_detail_config.description_name')"
          expanded
          icon="align-left"
        >
          <option
            v-for="field in fields"
            :key="`description-field-${field.name}`"
            :value="field"
          >
            {{ field.name }}
          </option>
        </b-select>
      </b-field>
      <b-field
        :label="$t('builder_detail_config.caption_and_link')"
        class="mt-4"
      >
        <b-select
          v-model="captionField"
          :placeholder="$t('builder_detail_config.caption_field')"
          expanded
          icon="grip-lines"
        >
          <option :value="''">
            {{ $t("builder_detail_config.caption_link") }}
          </option>
          <option
            v-for="field in fields"
            :key="`caption-field-${field.name}`"
            :value="field"
          >
            {{ field.name }}
          </option>
        </b-select>
      </b-field>
      <b-field
        :label="$t('builder_detail_config.caption_and_link')"
        class="mt-4"
      >
        <b-taginput
          v-model="customFields"
          :data="customFieldsFiltered"
          autocomplete
          open-on-focus
          field="name"
          :placeholder="$t('builder_detail_config.add_custom_fields')"
        >
        </b-taginput>
      </b-field>
      <div class="border-t border-gray-400 my-4"></div>
      <b-field :label="$t('builder_detail_config.action_type')" class="mt-4">
        <b-select
          v-model="ctaType"
          :placeholder="$t('builder_detail_config.action_type')"
          expanded
          icon="bolt"
        >
          <option :value="null">
            {{ $t("builder_detail_config.action_type") }}
          </option>
          <option
            v-for="actionTypeOption in actionTypesOptions"
            :key="`cta-field-${actionTypeOption.value}`"
            :value="actionTypeOption.value"
            :disabled="
              actionTypeOption.value === ActionTypes.NUM_CHECKOUT && !isPro
            "
          >
            {{ actionTypeOption.text }}
          </option>
        </b-select>
      </b-field>
      <b-field :label="buttonValueLabel" class="mt-4">
        <b-select
          v-model="ctaField"
          :placeholder="buttonValueLabel"
          expanded
          icon="columns"
        >
          <option
            :value="{
              name: '',
              type: ''
            }"
          >
            {{ $t("builder_detail_config.choose_button") }}
          </option>
          <option
            v-for="field in ctaFieldOptions"
            :key="`cta-field-${field.name}`"
            :value="field"
          >
            {{ field.name }}
          </option>
        </b-select>
      </b-field>
      <div>
        <div class="flex items-end">
          <b-field
            :label="$t('builder_detail_config.button_text')"
            class="w-full"
          >
            <b-input
              v-model="ctaText"
              :placeholder="$t('builder_detail_config.for_example')"
            ></b-input>
          </b-field>
          <div class="mb-2">
            <color-picker v-model="ctaBgColor" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BAlert from "@/components/BAlert";
import ColorPicker from "@/components/ColorPicker";
import ActionTypes from "@/constants/action-types.js";
import FieldTypes from "@/constants/field-types";
import ColorUtils from "@/utils/color";
import * as mutation from "@/store/modules/builder/mutation-types";

export default {
  name: "BuilderDetailConfig",

  components: {
    BAlert,
    ColorPicker
  },

  data() {
    return {
      ActionTypes
    };
  },

  computed: {
    isPro() {
      return this.$store.getters["builder/isPro"];
    },
    fields() {
      return this.$store.state.builder.fields;
    },
    hasSlugField() {
      return this.fields.find(el => el.name === "slug") || null;
    },
    linkFieldOptions() {
      return this.fields.filter(
        field =>
          field.type === FieldTypes.URL || field.type === FieldTypes.IMAGE
      );
    },
    captionField: {
      get() {
        return this.$store.state.builder.detailConfig.captionField;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_CAPTION}`,
          val
        );
      }
    },
    ctaField: {
      get() {
        return this.$store.state.builder.detailConfig.ctaField.field;
      },
      set(val) {
        if (this.ctaType === ActionTypes.NUM_CHECKOUT) {
          this.$store.commit(
            `builder/${mutation.SET_CHECKOUT_PRICE_FIELD}`,
            val.name
          );
        }
        this.$store.commit(`builder/${mutation.SET_DETAIL_CONFIG_CTA}`, val);
        this.$store.commit(`builder/${mutation.SET_LIST_ITEM_CONFIG_CTA}`, val);
      }
    },
    ctaFieldOptions() {
      if (!this.ctaType) {
        return [];
      }
      if (
        this.ctaType === ActionTypes.NUM_LINK ||
        this.ctaType === ActionTypes.NUM_DOWNLOAD
      ) {
        return this.fields.filter(
          field =>
            field.type === FieldTypes.URL || field.type === FieldTypes.IMAGE
        );
      }
      return this.fields;
    },
    ctaText: {
      get() {
        return this.$store.state.builder.detailConfig.ctaField.text;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_CTA_TEXT}`,
          val
        );
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_CTA_TEXT}`,
          val
        );
      }
    },
    ctaBgColor: {
      get() {
        return this.$store.state.builder.detailConfig.ctaField.bgColor;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_CTA_BG_COLOR}`,
          val
        );
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_CTA_COLOR}`,
          ColorUtils.invertColor(val, true)
        );
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_CTA_BG_COLOR}`,
          val
        );
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_CTA_COLOR}`,
          ColorUtils.invertColor(val, true)
        );
      }
    },
    ctaType: {
      get() {
        return this.$store.state.builder.detailConfig.ctaField.type;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_CTA_TYPE}`,
          val
        );
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_CTA_TYPE}`,
          val
        );
      }
    },
    customFields: {
      get() {
        return this.$store.state.builder.detailConfig.customFields;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_CUSTOM_FIELDS}`,
          val
        );
      }
    },
    customFieldsFiltered() {
      return this.fields.filter(
        f => !this.customFields.find(cf => cf.name === f.name)
      );
    },
    descriptionField: {
      get() {
        return this.$store.state.builder.detailConfig.descriptionField;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_DESCRIPTION}`,
          val
        );
      }
    },
    imageFields: {
      get() {
        return this.$store.state.builder.detailConfig.imageField.name;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_DETAIL_CONFIG_IMAGE}`, {
          name: val,
          type: this.$store.state.builder.detailConfig.imageField.type
        });
      }
    },
    imageFieldsFiltered() {
      return this.fields.reduce((arr, f) => {
        if (
          (f.type === FieldTypes.URL || f.type === FieldTypes.IMAGE) &&
          !this.imageFields.find(cf => cf === f.name)
        ) {
          arr.push(f.name);
        }
        return arr;
      }, []);
    },
    titleField: {
      get() {
        return this.$store.state.builder.detailConfig.titleField;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_DETAIL_CONFIG_TITLE}`, val);
      }
    },
    hideSinglePageView: {
      get() {
        return this.$store.state.builder.detailConfig.hide;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_DETAIL_CONFIG_HIDE}`, val);
      }
    },
    actionTypesOptions() {
      return ActionTypes.options.map(opt => {
        if (opt.value === ActionTypes.NUM_CHECKOUT && !this.isPro) {
          return {
            value: ActionTypes.NUM_CHECKOUT,
            text: "Add to shopping cart (upgrade to pro)"
          };
        }
        return { ...opt };
      });
    },
    buttonValueLabel() {
      if (this.ctaType === ActionTypes.NUM_LINK) {
        return this.$t("builder_detail_config.link_column");
      } else if (this.ctaType === ActionTypes.NUM_CHECKOUT) {
        return this.$t("builder_detail_config.price_column");
      } else if (this.ctaType === ActionTypes.NUM_DOWNLOAD) {
        return this.$t("builder_detail_config.download_column");
      }
      return this.$t("builder_detail_config.button_column");
    }
  },

  watch: {
    ctaField(newVal, oldVal) {
      if (!oldVal.name && newVal.name) {
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_CTA_TEXT}`,
          "Buy"
        );
      } else if (!newVal.name) {
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_CTA_TEXT}`,
          ""
        );
      }
    }
  }
};
</script>
