<template>
  <div>
    <b-field>
      <b-checkbox v-model="show">
        {{ $t("builder.show") }}
      </b-checkbox>
    </b-field>
    <text-editor v-model="content" class="mb-3" />
    <b-field :label="$t('builder.background_color')">
      <div class="flex">
        <b-input v-model="backgroundColor"></b-input>
        <color-picker v-model="backgroundColor" />
      </div>
    </b-field>
    <b-field :label="$t('builder.text_color')">
      <div class="flex">
        <b-input v-model="textColor"></b-input>
        <color-picker v-model="textColor" />
      </div>
    </b-field>
    <b-field>
      <b-checkbox v-model="showSocialFacebook">
        {{ $t("builder.show") }}
        Facebook
      </b-checkbox>
    </b-field>
    <b-field>
      <b-checkbox v-model="showSocialInstagram">
        {{ $t("builder.show") }}
        Instagram
      </b-checkbox>
    </b-field>
    <b-field>
      <b-checkbox v-model="showSocialX">
        {{ $t("builder.show") }}
        X
      </b-checkbox>
    </b-field>
    <b-field>
      <b-checkbox v-model="showSocialTiktok">
        {{ $t("builder.show") }}
        TikTok
      </b-checkbox>
    </b-field>
    <b-field :label="$t('builder.facebook_account_url')">
      <div class="flex">
        <b-input
          v-model="socialFacebookUrl"
          class="w-full"
          :disabled="!showSocialFacebook"
        ></b-input>
      </div>
    </b-field>
    <b-field :label="$t('builder.instagram_account_url')">
      <div class="flex">
        <b-input
          v-model="socialInstagramUrl"
          class="w-full"
          :disabled="!showSocialInstagram"
        ></b-input>
      </div>
    </b-field>
    <b-field :label="$t('builder.x_account_url')">
      <div class="flex">
        <b-input
          v-model="socialXUrl"
          class="w-full"
          :disabled="!showSocialX"
        ></b-input>
      </div>
    </b-field>
    <b-field :label="$t('builder.tiktok_account_url')">
      <div class="flex">
        <b-input
          v-model="socialTiktokUrl"
          class="w-full"
          :disabled="!showSocialTiktok"
        ></b-input>
      </div>
    </b-field>
  </div>
</template>

<script>
import ColorPicker from "@/components/ColorPicker";
import TextEditor from "@/components/TextEditor";
import * as mutation from "@/store/modules/builder/mutation-types";

export default {
  name: "FooterConfig",

  components: {
    ColorPicker,
    TextEditor
  },

  computed: {
    backgroundColor: {
      get() {
        return this.$store.state.builder.footer.backgroundColor;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_FOOTER_BACKGROUND_COLOR}`,
          val
        );
      }
    },
    textColor: {
      get() {
        return this.$store.state.builder.footer.textColor;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_FOOTER_TEXT_COLOR}`, val);
      }
    },
    content: {
      get() {
        return this.$store.state.builder.footer.content;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_FOOTER_CONTENT}`, val);
      }
    },
    show: {
      get() {
        return this.$store.state.builder.footer.show;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_FOOTER_SHOW}`, val);
      }
    },
    showSocialFacebook: {
      get() {
        return this.$store.state.builder.footer.socials.facebook.show;
      },
      set(val) {
        const cfg = { key: "facebook", show: val };
        this.$store.dispatch("builder/setFooterSocialShowIcon", cfg);
      }
    },
    showSocialInstagram: {
      get() {
        return this.$store.state.builder.footer.socials.instagram.show;
      },
      set(val) {
        const cfg = { key: "instagram", show: val };
        this.$store.dispatch("builder/setFooterSocialShowIcon", cfg);
      }
    },
    showSocialX: {
      get() {
        return this.$store.state.builder.footer.socials.x.show;
      },
      set(val) {
        const cfg = { key: "x", show: val };
        this.$store.dispatch("builder/setFooterSocialShowIcon", cfg);
      }
    },
    showSocialTiktok: {
      get() {
        return this.$store.state.builder.footer.socials.tiktok.show;
      },
      set(val) {
        const cfg = { key: "tiktok", show: val };
        this.$store.dispatch("builder/setFooterSocialShowIcon", cfg);
      }
    },
    /* */
    socialFacebookUrl: {
      get() {
        return this.$store.state.builder.footer.socials.facebook.url;
      },
      set(val) {
        const cfg = { key: "facebook", url: val };
        this.$store.dispatch("builder/setFooterSocialUrl", cfg);
      }
    },
    socialInstagramUrl: {
      get() {
        return this.$store.state.builder.footer.socials.instagram.url;
      },
      set(val) {
        const cfg = { key: "instagram", url: val };
        this.$store.dispatch("builder/setFooterSocialUrl", cfg);
      }
    },
    socialXUrl: {
      get() {
        return this.$store.state.builder.footer.socials.x.url;
      },
      set(val) {
        const cfg = { key: "x", url: val };
        this.$store.dispatch("builder/setFooterSocialUrl", cfg);
      }
    },
    socialTiktokUrl: {
      get() {
        return this.$store.state.builder.footer.socials.tiktok.url;
      },
      set(val) {
        const cfg = { key: "tiktok", url: val };
        this.$store.dispatch("builder/setFooterSocialUrl", cfg);
      }
    }
  }
};
</script>
