<template>
  <div>
    <div>
      <div class="flex">
        <label class="font-bold text-md mr-3">{{
          $t("builder_seo.meta_title")
        }}</label>
        <b-tooltip
          label="Choose the meta title for the home route and for each sheet item"
          size="is-small"
          multilined
        >
          <div>
            <b-icon name="question-circle" />
          </div>
        </b-tooltip>
      </div>
      <span class="text-sm">{{
        $t("builder_seo.recommended_characteres")
      }}</span>
      <b-input
        v-model="homeTitle"
        :placeholder="$t('builder_seo.home_screen_title')"
        class="mt-2"
        :disabled="!isPro"
      ></b-input>
      <b-select
        v-model="itemTitle"
        :placeholder="$t('builder_seo.single_page_description')"
        expanded
        icon="align-left"
        class="mt-3"
        :disabled="!isPro"
      >
        <option :value="''">
          {{ $t("builder_seo.single_page_title") }}
        </option>
        <option
          v-for="field in fields"
          :key="`filter-field-${field.name}`"
          :value="field"
        >
          {{ field.name }}
        </option>
      </b-select>
    </div>
    <div class="mt-3">
      <div class="flex">
        <label class="font-bold text-md mr-3">
          {{ $t("builder_seo.meta_description") }}
        </label>
        <b-tooltip
          label="Choose the meta description for the home route and each sheet item"
          size="is-small"
          multilined
        >
          <div class="pb-1">
            <b-icon name="question-circle" />
          </div>
        </b-tooltip>
      </div>
      <span class="text-sm">{{ $t("builder_seo.publish") }}</span>
      <b-input
        v-model="homeDescription"
        :placeholder="$t('builder_seo.home_meta_description')"
        class="mt-2"
        :disabled="!isPro"
      ></b-input>
      <b-select
        v-model="itemDescription"
        :placeholder="$t('builder_seo.single_page_view')"
        expanded
        icon="align-left"
        class="mt-3"
        :disabled="!isPro"
      >
        <option :value="''">
          {{ $t("builder_seo.publish") }}
        </option>
        <option
          v-for="field in fields"
          :key="`filter-field-${field.name}`"
          :value="field"
        >
          {{ field.name }}
        </option>
      </b-select>
    </div>
  </div>
</template>

<script>
import BIcon from "@/components/BIcon";

export default {
  name: "BuilderSeo",

  components: {
    BIcon
  },

  computed: {
    isPro() {
      return this.$store.getters["builder/isPro"];
    },
    fields() {
      return this.$store.state.builder.fields;
    },
    homeDescription: {
      get() {
        return this.$store.state.builder.metaFields.homeDescription;
      },
      set(val) {
        this.$store.commit("builder/SET_META_DESCRIPTION_HOME", val);
      }
    },
    homeTitle: {
      get() {
        return this.$store.state.builder.metaFields.homeTitle;
      },
      set(val) {
        this.$store.commit("builder/SET_META_TITLE_HOME", val);
      }
    },
    itemDescription: {
      get() {
        return this.$store.state.builder.metaFields.itemDescription;
      },
      set(val) {
        this.$store.commit("builder/SET_META_DESCRIPTION_ITEM", val);
      }
    },
    itemTitle: {
      get() {
        return this.$store.state.builder.metaFields.itemTitle;
      },
      set(val) {
        this.$store.commit("builder/SET_META_TITLE_ITEM", val);
      }
    }
  }
};
</script>
