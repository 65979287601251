<template>
  <div>
    <div class="field">
      <b-switch v-model="showHero">
        {{ $t("page_header.show") }}
      </b-switch>
    </div>
    <b-field :label="$t('page_header.title')">
      <div class="flex w-full">
        <b-input
          v-model="title"
          placeholder=""
          class="w-full"
          expanded
        ></b-input>
        <color-picker v-model="titleColor" />
      </div>
    </b-field>
    <b-field :label="$t('page_header.subtitle')">
      <div class="flex">
        <b-input
          v-model="subheading"
          placeholder=""
          class="w-full"
          expanded
        ></b-input>
        <color-picker v-model="subheadingColor" />
      </div>
    </b-field>
    <b-field
      :label="$t('page_header.image')"
      :type="imageFieldType"
      :message="$t('page_header.image_limit')"
    >
      <b-upload
        v-model="imageFile"
        expanded
        accept=".jpg,.jpeg,.png,.webp"
        class="text-red"
        :loading="uploadingImage"
        :disabled="uploadingImage"
      >
        <a class="button is-fullwidth">
          <b-icon name="upload" class="mr-3"></b-icon>
          <span v-if="uploadingImage">{{ $t("page_header.uploading") }}</span>
          <span v-else>{{ $t("page_header.upload_image") }}</span>
        </a>
      </b-upload>
    </b-field>
  </div>
</template>

<script>
import BIcon from "@/components/BIcon";
import ColorPicker from "@/components/ColorPicker";
import * as mutation from "@/store/modules/builder/mutation-types";
import { fileSize } from "@/utils/file";

export default {
  name: "PageHeader",

  components: {
    BIcon,
    ColorPicker
  },

  data() {
    return {
      imageFile: null,
      uploadingImage: false
    };
  },

  computed: {
    showHero: {
      get() {
        return this.$store.state.builder.listItemConfig?.hero?.show;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_LIST_ITEM_HERO_SHOW}`, val);
      }
    },
    title: {
      get() {
        return this.$store.state.builder.listItemConfig?.hero?.title?.value;
      },
      set(value) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_HERO_TITLE_VALUE}`,
          value
        );
      }
    },
    titleColor: {
      get() {
        return this.$store.state.builder.listItemConfig?.hero?.title?.color;
      },
      set(color) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_HERO_TITLE_COLOR}`,
          color
        );
      }
    },
    subheading: {
      get() {
        return this.$store.state.builder.listItemConfig?.hero?.subheading
          ?.value;
      },
      set(value) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_HERO_SUBHEADING_VALUE}`,
          value
        );
      }
    },
    subheadingColor: {
      get() {
        return this.$store.state.builder.listItemConfig?.hero?.subheading
          ?.color;
      },
      set(value) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_HERO_SUBHEADING_COLOR}`,
          value
        );
      }
    },
    invalidImage() {
      if (!this.imageFile) return false;
      const size = fileSize(this.imageFile);
      return size > 2;
    },
    imageFieldType() {
      if (this.invalidImage) {
        return "is-danger";
      }
      return "";
    }
  },

  watch: {
    imageFile(val) {
      if (!this.invalidImage) {
        this.uploadingImage = true;
        this.$store.dispatch("builder/updloadHeaderImage", val).finally(() => {
          this.uploadingImage = false;
        });
      }
    }
  }
};
</script>
