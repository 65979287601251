<template>
  <div>
    <b-field :label="$t('builder_list_item_config.filter_by')">
      <b-select
        v-model="filterByField"
        :placeholder="$t('builder_list_item_config.filter_filter')"
        expanded
        icon="filter"
      >
        <option :value="''">
          {{ $t("builder_list_item_config.hide_filter") }}
        </option>
        <option
          v-for="field in fields"
          :key="`filter-field-${field.name}`"
          :value="field"
        >
          {{ field.name }}
        </option>
      </b-select>
    </b-field>
    <!-- <b-field label="Filter by" class="mt-4">
      <b-taginput
        v-model="filterByField"
        :data="fieldsFiltered"
        autocomplete
        open-on-focus
        field="name"
        placeholder="Add a filter field"
      >
      </b-taginput>
    </b-field> -->
    <div class="border-t border-gray-300 my-4"></div>
    <b-field :label="$t('builder_list_item_config.image')" class="mt-4">
      <b-taginput
        v-model="imageFields"
        :data="imageFieldsFiltered"
        autocomplete
        open-on-focus
        field="name"
        :placeholder="$t('builder_list_item_config.add_image_field')"
      >
      </b-taginput>
    </b-field>
    <b-field :label="$t('builder_list_item_config.title')" class="mt-4">
      <b-select
        v-model="titleField"
        :placeholder="$t('builder_list_item_config.tittle_field_name')"
        expanded
        icon="heading"
      >
        <option
          v-for="field in fields"
          :key="`title-field-${field.name}`"
          :value="field"
        >
          {{ field.name }}
        </option>
      </b-select>
    </b-field>
    <b-field :label="$t('builder_list_item_config.description')" class="mt-4">
      <b-select
        v-model="descriptionField"
        :placeholder="$t('builder_list_item_config.description_field_name')"
        expanded
        icon="align-left"
      >
        <option
          v-for="field in fields"
          :key="`description-field-${field.name}`"
          :value="field"
        >
          {{ field.name }}
        </option>
      </b-select>
    </b-field>
    <b-field
      :label="$t('builder_list_item_config.caption_and_link')"
      class="mt-4"
    >
      <b-select
        v-model="captionField"
        :placeholder="$t('builder_list_item_config.caption_field_name')"
        expanded
        icon="grip-lines"
      >
        <option :value="''">{{
          $t("builder_list_item_config.description_field_name")
        }}</option>
        <option
          v-for="field in fields"
          :key="`caption-field-${field.name}`"
          :value="field"
        >
          {{ field.name }}
        </option>
      </b-select>
    </b-field>
    <b-field :label="$t('builder_list_item_config.custom_fields')" class="mt-4">
      <b-taginput
        v-model="customFields"
        :data="customFieldsFiltered"
        autocomplete
        open-on-focus
        field="name"
        :placeholder="$t('builder_list_item_config.description_field_name')"
      >
      </b-taginput>
    </b-field>
    <div class="border-t border-gray-300 my-4 mb-2"></div>
    <b-field :label="$t('builder_list_item_config.action_type')" class="mt-4">
      <b-select
        v-model="ctaType"
        :placeholder="$t('builder_list_item_config.action_type')"
        expanded
        icon="bolt"
      >
        <option :value="null">{{
          $t("builder_list_item_config.choose_action_type")
        }}</option>
        <option
          v-for="actionTypeOption in actionTypesOptions"
          :key="`cta-field-${actionTypeOption.value}`"
          :value="actionTypeOption.value"
          :disabled="
            actionTypeOption.value === ActionTypes.NUM_CHECKOUT && !isPro
          "
        >
          {{ actionTypeOption.text }}
        </option>
      </b-select>
    </b-field>
    <b-field :label="buttonValueLabel" class="mt-4">
      <b-select
        v-model="ctaField"
        :placeholder="buttonValueLabel"
        expanded
        icon="columns"
      >
        <option
          :value="{
            name: '',
            type: ''
          }"
        >
          {{ $t("builder_list_item_config.choose_button_column") }}
        </option>
        <option
          v-for="field in ctaFieldOptions"
          :key="`cta-field-${field.name}`"
          :value="field"
        >
          {{ field.name }}
        </option>
      </b-select>
    </b-field>
    <div>
      <div class="flex items-end">
        <b-field
          :label="$t('builder_list_item_config.button_text')"
          class="w-full"
        >
          <b-input
            v-model="ctaText"
            :placeholder="$t('builder_list_item_config.for_example')"
          ></b-input>
        </b-field>
        <div class="mb-2">
          <color-picker v-model="ctaBgColor" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/components/ColorPicker";
import FieldTypes from "@/constants/field-types";
import ColorUtils from "@/utils/color";
import ActionTypes from "@/constants/action-types.js";
import * as mutation from "@/store/modules/builder/mutation-types";

export default {
  name: "BuilderListItemConfig",

  components: {
    ColorPicker
  },

  data() {
    return {
      ActionTypes
    };
  },

  computed: {
    isPro() {
      return this.$store.getters["builder/isPro"];
    },
    fields() {
      return this.$store.state.builder.fields;
    },
    fieldsFiltered() {
      const seleccionados = this.filterByField || [];
      return this.fields.filter(
        ({ name }) => !seleccionados.find(f => f.name === name)
      );
    },
    linkFieldOptions() {
      return this.fields.filter(
        field =>
          field.type === FieldTypes.URL || field.type === FieldTypes.IMAGE
      );
    },
    captionField: {
      get() {
        return this.$store.state.builder.listItemConfig.captionField;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_CAPTION}`,
          val
        );
      }
    },
    ctaFieldOptions() {
      if (!this.ctaType) {
        return [];
      }
      if (
        this.ctaType === ActionTypes.NUM_LINK ||
        this.ctaType === ActionTypes.NUM_DOWNLOAD
      ) {
        return this.fields.filter(
          field =>
            field.type === FieldTypes.URL || field.type === FieldTypes.IMAGE
        );
      }
      return this.fields;
    },
    ctaField: {
      get() {
        return this.$store.state.builder.listItemConfig.ctaField.field;
      },
      set(val) {
        if (this.ctaType === ActionTypes.NUM_CHECKOUT) {
          this.$store.commit(
            `builder/${mutation.SET_CHECKOUT_PRICE_FIELD}`,
            val.name
          );
        }
        this.$store.commit(`builder/${mutation.SET_DETAIL_CONFIG_CTA}`, val);
        this.$store.commit(`builder/${mutation.SET_LIST_ITEM_CONFIG_CTA}`, val);
      }
    },
    ctaText: {
      get() {
        return this.$store.state.builder.listItemConfig.ctaField.text;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_CTA_TEXT}`,
          val
        );
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_CTA_TEXT}`,
          val
        );
      }
    },
    ctaBgColor: {
      get() {
        return this.$store.state.builder.listItemConfig.ctaField.bgColor;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_CTA_BG_COLOR}`,
          val
        );
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_CTA_COLOR}`,
          ColorUtils.invertColor(val, true)
        );
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_CTA_BG_COLOR}`,
          val
        );
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_CTA_COLOR}`,
          ColorUtils.invertColor(val, true)
        );
      }
    },
    ctaType: {
      get() {
        return this.$store.state.builder.listItemConfig.ctaField.type;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_CTA_TYPE}`,
          val
        );
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_CTA_TYPE}`,
          val
        );
      }
    },
    customFields: {
      get() {
        return this.$store.state.builder.listItemConfig.customFields;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_CUSTOM_FIELDS}`,
          val
        );
      }
    },
    customFieldsFiltered() {
      return this.fields.filter(
        f => !this.customFields.find(cf => cf.name === f.name)
      );
    },
    descriptionField: {
      get() {
        return this.$store.state.builder.listItemConfig.descriptionField;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_DESCRIPTION}`,
          val
        );
      }
    },
    filterByField: {
      get() {
        return this.$store.state.builder.listItemConfig.filterByField;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_LIST_ITEM_FILTER_BY}`, val);
      }
    },
    imageFields: {
      get() {
        return this.$store.state.builder.listItemConfig.imageField.name;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_LIST_ITEM_CONFIG_IMAGE}`, {
          name: val,
          type: this.$store.state.builder.listItemConfig.imageField.type
        });
      }
    },
    imageFieldsFiltered() {
      return this.fields.reduce((arr, f) => {
        if (
          (f.type === FieldTypes.URL || f.type === FieldTypes.IMAGE) &&
          !this.imageFields.find(cf => cf === f.name)
        ) {
          arr.push(f.name);
        }
        return arr;
      }, []);
    },
    titleField: {
      get() {
        return this.$store.state.builder.listItemConfig.titleField;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_TITLE}`,
          val
        );
      }
    },
    sheetId() {
      return this.$store.state.builder.sheetId;
    },
    actionTypesOptions() {
      return ActionTypes.options.map(opt => {
        if (opt.value === ActionTypes.NUM_CHECKOUT && !this.isPro) {
          return {
            value: ActionTypes.NUM_CHECKOUT,
            text: `${opt.text} (upgrade to pro)`
          };
        }
        return { ...opt };
      });
    },
    buttonValueLabel() {
      if (this.ctaType === ActionTypes.NUM_LINK) {
        return this.$t("builder_list_item_config.link_column");
      } else if (this.ctaType === ActionTypes.NUM_CHECKOUT) {
        return this.$t("builder_list_item_config.price_column");
      } else if (this.ctaType === ActionTypes.NUM_DOWNLOAD) {
        return this.$t("builder_list_item_config.download_column");
      }
      return this.$t("builder_list_item_config.button_column");
    }
  },

  watch: {
    filterByField(newVal, oldVal) {
      if (newVal?.name !== oldVal?.name) {
        this.getSheet();
      }
    },
    ctaField(newVal, oldVal) {
      if (!oldVal.name && newVal.name) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_CTA_TEXT}`,
          "Buy"
        );
        this.$store.commit(
          `builder/${mutation.SET_DETAIL_CONFIG_CTA_TEXT}`,
          "Buy"
        );
      } else if (!newVal.name) {
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_CTA_TEXT}`,
          ""
        );
        this.$store.commit(
          `builder/${mutation.SET_LIST_ITEM_CONFIG_CTA_TEXT}`,
          ""
        );
      }
    }
  },

  methods: {
    async getSheet() {
      try {
        this.loading = true;
        await this.$store.dispatch("builder/getSheet", {
          sheetId: this.sheetId
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
