<template>
  <div>
    <b-field :label="$t('whats_app_checkout.hello_message_basic')">
      <b-input
        :value="helloMessage"
        :placeholder="$t('whats_app_checkout.hello_message')"
        :disabled="!isPro"
        @input="
          $event => {
            helloMessage = $event;
          }
        "
      ></b-input>
    </b-field>
    <b-field :label="$t('whats_app_checkout.message_ending')">
      <b-input
        v-model="messageEnding"
        :placeholder="$t('whats_app_checkout.instruccion_received')"
        :disabled="!isPro"
      ></b-input>
    </b-field>
    <b-field :label="$t('whats_app_checkout.whats_app_number')">
      <vue-tel-input
        :value="sellerPhone"
        :input-options="{ showDialCode: true }"
        :disabled="!isPro"
        @input="
          $event => {
            sellerPhone = $event;
          }
        "
      />
    </b-field>
  </div>
</template>

<script>
import * as mutation from "@/store/modules/builder/mutation-types";
import { VueTelInput } from "vue-tel-input";

export default {
  name: "WhatsAppCheckout",

  components: {
    VueTelInput
  },

  computed: {
    isPro() {
      return this.$store.getters["builder/isPro"];
    },
    helloMessage: {
      get() {
        return this.$store.state.builder.checkout.helloMessage;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_CHECKOUT_HELLO_MESSAGE}`,
          val
        );
      }
    },
    messageEnding: {
      get() {
        return this.$store.state.builder.checkout.messageEnding;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_CHECKOUT_MESSAGE_ENDING}`,
          val
        );
      }
    },
    sellerPhone: {
      get() {
        return this.$store.state.builder.checkout.phoneNumber;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_CHECKOUT_PHONE_NUMBER}`,
          val
        );
      }
    }
  }
};
</script>
