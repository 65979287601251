<template>
  <div>
    <div class="font-bold uppercase">
      {{ $t("check_out_form_fields.form_fields") }}
    </div>
    <b-field :label="$t('check_out_form_fields.name')">
      <div>
        <b-checkbox
          :disabled="!isPro"
          :value="name.show"
          @input="
            $event => {
              name = { required: name.required, show: $event };
            }
          "
        >
          {{ $t("check_out_form_fields.show") }}
        </b-checkbox>
        <b-checkbox
          :disabled="!isPro"
          :value="name.required"
          @input="
            $event => {
              name = { required: $event, show: name.show };
            }
          "
        >
          {{ $t("check_out_form_fields.required") }}
        </b-checkbox>
      </div>
    </b-field>
    <b-field :label="$t('check_out_form_fields.email')">
      <div>
        <b-checkbox
          :disabled="!isPro"
          :value="email.show"
          @input="
            $event => {
              email = { required: email.required, show: $event };
            }
          "
        >
          {{ $t("check_out_form_fields.show") }}
        </b-checkbox>
        <b-checkbox
          :disabled="!isPro"
          :value="email.required"
          @input="
            $event => {
              email = { required: $event, show: email.show };
            }
          "
        >
          {{ $t("check_out_form_fields.required") }}
        </b-checkbox>
      </div>
    </b-field>
    <b-field :label="$t('check_out_form_fields.phone_number')">
      <div>
        <b-checkbox
          :disabled="!isPro"
          :value="phoneNumber.show"
          @input="
            $event => {
              phoneNumber = { required: phoneNumber.required, show: $event };
            }
          "
        >
          {{ $t("check_out_form_fields.show") }}
        </b-checkbox>
        <b-checkbox
          :disabled="!isPro"
          :value="phoneNumber.required"
          @input="
            $event => {
              phoneNumber = { required: $event, show: phoneNumber.show };
            }
          "
        >
          {{ $t("check_out_form_fields.required") }}
        </b-checkbox>
      </div>
    </b-field>
    <b-field :label="$t('check_out_form_fields.address')">
      <div>
        <b-checkbox
          :disabled="!isPro"
          :value="address.show"
          @input="
            $event => {
              address = { required: address.required, show: $event };
            }
          "
        >
          {{ $t("check_out_form_fields.show") }}
        </b-checkbox>
        <b-checkbox
          :disabled="!isPro"
          :value="address.required"
          @input="
            $event => {
              address = { required: $event, show: address.show };
            }
          "
        >
          {{ $t("check_out_form_fields.required") }}
        </b-checkbox>
      </div>
    </b-field>
    <b-field :label="$t('check_out_form_fields.zip_code')">
      <div>
        <b-checkbox
          :disabled="!isPro"
          :value="zipCode.show"
          @input="
            $event => {
              zipCode = { required: zipCode.required, show: $event };
            }
          "
        >
          {{ $t("check_out_form_fields.show") }}
        </b-checkbox>
        <b-checkbox
          :disabled="!isPro"
          :value="zipCode.required"
          @input="
            $event => {
              zipCode = { required: $event, show: zipCode.show };
            }
          "
        >
          {{ $t("check_out_form_fields.required") }}
        </b-checkbox>
      </div>
    </b-field>
    <b-field :label="$t('check_out_form_fields.country')">
      <div>
        <b-checkbox
          :disabled="!isPro"
          :value="country.show"
          @input="
            $event => {
              country = { required: country.required, show: $event };
            }
          "
        >
          {{ $t("check_out_form_fields.show") }}
        </b-checkbox>
        <b-checkbox
          :disabled="!isPro"
          :value="country.required"
          @input="
            $event => {
              country = { required: $event, show: country.show };
            }
          "
        >
          {{ $t("check_out_form_fields.required") }}
        </b-checkbox>
      </div>
    </b-field>
    <b-field :label="$t('check_out_form_fields.state')">
      <div>
        <b-checkbox
          :disabled="!isPro"
          :value="state.show"
          @input="
            $event => {
              state = { required: state.required, show: $event };
            }
          "
        >
          {{ $t("check_out_form_fields.show") }}
        </b-checkbox>
        <b-checkbox
          :disabled="!isPro"
          :value="state.required"
          @input="
            $event => {
              state = { required: $event, show: state.show };
            }
          "
        >
          {{ $t("check_out_form_fields.required") }}
        </b-checkbox>
      </div>
    </b-field>
    <b-field :label="$t('check_out_form_fields.city')">
      <div>
        <b-checkbox
          :disabled="!isPro"
          :value="city.show"
          @input="
            $event => {
              city = { required: city.required, show: $event };
            }
          "
        >
          {{ $t("check_out_form_fields.show") }}
        </b-checkbox>
        <b-checkbox
          :disabled="!isPro"
          :value="city.required"
          @input="
            $event => {
              city = { required: $event, show: city.show };
            }
          "
        >
          {{ $t("check_out_form_fields.required") }}
        </b-checkbox>
      </div>
    </b-field>
    <b-field :label="$t('check_out_form_fields.notes')">
      <div>
        <b-checkbox
          :disabled="!isPro"
          :value="notes.show"
          @input="
            $event => {
              notes = { required: notes.required, show: $event };
            }
          "
        >
          {{ $t("check_out_form_fields.show") }}
        </b-checkbox>
        <b-checkbox
          :disabled="!isPro"
          :value="notes.required"
          @input="
            $event => {
              notes = { required: $event, show: notes.show };
            }
          "
        >
          {{ $t("check_out_form_fields.required") }}
        </b-checkbox>
      </div>
    </b-field>
    <b-field :label="$t('check_out_form_fields.use_google_maps')">
      <div>
        <b-checkbox v-model="googleMaps" :disabled="!isPro">
          {{ $t("check_out_form_fields.enable") }}
        </b-checkbox>
      </div>
    </b-field>
    <div class="font-bold uppercase">
      {{ $t("check_out_form_fields.payment_options") }}
    </div>
    <b-field :label="$t('check_out_form_fields.cash')">
      <div>
        <b-checkbox v-model="paymentMethodCash" :disabled="!isPro">
          {{ $t("check_out_form_fields.enable") }}
        </b-checkbox>
      </div>
    </b-field>
    <b-field :label="$t('check_out_form_fields.credit_card')">
      <div>
        <b-checkbox v-model="paymentMethodCard" :disabled="!isPro">
          {{ $t("check_out_form_fields.enable") }}
        </b-checkbox>
      </div>
    </b-field>

    <b-taginput
      v-model="customPaymentOptions"
      field="name"
      :placeholder="$t('check_out_form_fields.enable')"
    >
    </b-taginput>

    <div class="font-bold uppercase mt-4">
      {{ $t("check_out_form_fields.pickup_and_delivery") }}
    </div>
    <b-field :label="$t('check_out_form_fields.pickup')">
      <div>
        <b-checkbox
          :disabled="!isPro"
          :value="pickup.available"
          @input="
            $event => {
              pickup = { available: $event };
            }
          "
        >
          {{ $t("check_out_form_fields.enable") }}
        </b-checkbox>
      </div>
    </b-field>
    <b-field :label="$t('check_out_form_fields.delivery')">
      <div class="flex">
        <b-checkbox
          :disabled="!isPro"
          :value="delivery.available"
          @input="
            $event => {
              delivery = { available: $event, cost: delivery.cost };
            }
          "
        >
          {{ $t("check_out_form_fields.enable") }}
        </b-checkbox>
        <b-input
          :placeholder="$t('check_out_form_fields.delivery_cost')"
          type="number"
          min="0"
          step="0.01"
          class="the200Width"
          :disabled="!isPro"
          :value="delivery.cost"
          @input="handleSetDelivery"
        >
        </b-input>
      </div>
    </b-field>
  </div>
</template>

<script>
import * as mutation from "@/store/modules/builder/mutation-types";

export default {
  name: "CheckoutFormFields",

  computed: {
    isPro() {
      return this.$store.getters["builder/isPro"];
    },
    name: {
      get() {
        return this.$store.state.builder.checkout.form.name;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_CHECKOUT_FORM_NAME}`, val);
      }
    },
    email: {
      get() {
        return this.$store.state.builder.checkout.form.email;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_CHECKOUT_FORM_EMAIL}`, val);
      }
    },
    address: {
      get() {
        return this.$store.state.builder.checkout.form.address;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_CHECKOUT_FORM_ADDRESS}`,
          val
        );
      }
    },
    country: {
      get() {
        return this.$store.state.builder.checkout.form.country;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_CHECKOUT_FORM_COUNTRY}`,
          val
        );
      }
    },
    notes: {
      get() {
        return this.$store.state.builder.checkout.form.notes;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_CHECKOUT_FORM_NOTES}`, val);
      }
    },
    googleMaps: {
      get() {
        return this.$store.state.builder.checkout.form.googleMaps;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_CHECKOUT_FORM_GOOGLEMAPS}`,
          val
        );
      }
    },
    paymentMethods: {
      get() {
        return this.$store.state.builder.checkout.form.paymentMethods;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_CHECKOUT_FORM_PAYMENT_METHODS}`,
          val
        );
      }
    },
    paymentMethodCash: {
      get() {
        return this.$store.state.builder.checkout.form.paymentMethods.cash;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_CHECKOUT_FORM_PAYMENT_OPTIONS_CASH}`,
          val
        );
      }
    },
    paymentMethodCard: {
      get() {
        return this.$store.state.builder.checkout.form.paymentMethods
          .creditCard;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_CHECKOUT_FORM_PAYMENT_OPTIONS_CARD}`,
          val
        );
      }
    },
    customPaymentOptions: {
      get() {
        return this.$store.state.builder.checkout.form.paymentMethods.custom;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_CHECKOUT_SET_PAYMENT_OPTIONS}`,
          val
        );
      }
    },
    phoneNumber: {
      get() {
        return this.$store.state.builder.checkout.form.phoneNumber;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_CHECKOUT_FORM_PHONE_NUMBER}`,
          val
        );
      }
    },
    pickup: {
      get() {
        return this.$store.state.builder.checkout.form.pickup;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_CHECKOUT_FORM_PICKUP}`, val);
      }
    },
    delivery: {
      get() {
        return this.$store.state.builder.checkout.form.delivery;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_CHECKOUT_FORM_DELIVERY}`,
          val
        );
      }
    },
    city: {
      get() {
        return this.$store.state.builder.checkout.form.city;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_CHECKOUT_FORM_CITY}`, val);
      }
    },
    state: {
      get() {
        return this.$store.state.builder.checkout.form.state;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_CHECKOUT_FORM_STATE}`, val);
      }
    },
    zipCode: {
      get() {
        return this.$store.state.builder.checkout.form.zipCode;
      },
      set(val) {
        this.$store.commit(
          `builder/${mutation.SET_CHECKOUT_FORM_ZIP_CODE}`,
          val
        );
      }
    }
  },
  methods: {
    handleSetDelivery($event) {
      console.log("handleSetDelivery $event", $event);
      this.delivery = {
        available: this.delivery.available,
        cost: $event
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.the200Width {
  width: 200px;
}
</style>
