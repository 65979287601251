var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"font-bold uppercase"},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.form_fields"))+" ")]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.name')}},[_c('div',[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.name.show},on:{"input":function ($event) {
            _vm.name = { required: _vm.name.required, show: $event };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.show"))+" ")]),_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.name.required},on:{"input":function ($event) {
            _vm.name = { required: $event, show: _vm.name.show };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.required"))+" ")])],1)]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.email')}},[_c('div',[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.email.show},on:{"input":function ($event) {
            _vm.email = { required: _vm.email.required, show: $event };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.show"))+" ")]),_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.email.required},on:{"input":function ($event) {
            _vm.email = { required: $event, show: _vm.email.show };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.required"))+" ")])],1)]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.phone_number')}},[_c('div',[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.phoneNumber.show},on:{"input":function ($event) {
            _vm.phoneNumber = { required: _vm.phoneNumber.required, show: $event };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.show"))+" ")]),_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.phoneNumber.required},on:{"input":function ($event) {
            _vm.phoneNumber = { required: $event, show: _vm.phoneNumber.show };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.required"))+" ")])],1)]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.address')}},[_c('div',[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.address.show},on:{"input":function ($event) {
            _vm.address = { required: _vm.address.required, show: $event };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.show"))+" ")]),_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.address.required},on:{"input":function ($event) {
            _vm.address = { required: $event, show: _vm.address.show };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.required"))+" ")])],1)]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.zip_code')}},[_c('div',[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.zipCode.show},on:{"input":function ($event) {
            _vm.zipCode = { required: _vm.zipCode.required, show: $event };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.show"))+" ")]),_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.zipCode.required},on:{"input":function ($event) {
            _vm.zipCode = { required: $event, show: _vm.zipCode.show };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.required"))+" ")])],1)]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.country')}},[_c('div',[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.country.show},on:{"input":function ($event) {
            _vm.country = { required: _vm.country.required, show: $event };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.show"))+" ")]),_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.country.required},on:{"input":function ($event) {
            _vm.country = { required: $event, show: _vm.country.show };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.required"))+" ")])],1)]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.state')}},[_c('div',[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.state.show},on:{"input":function ($event) {
            _vm.state = { required: _vm.state.required, show: $event };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.show"))+" ")]),_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.state.required},on:{"input":function ($event) {
            _vm.state = { required: $event, show: _vm.state.show };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.required"))+" ")])],1)]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.city')}},[_c('div',[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.city.show},on:{"input":function ($event) {
            _vm.city = { required: _vm.city.required, show: $event };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.show"))+" ")]),_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.city.required},on:{"input":function ($event) {
            _vm.city = { required: $event, show: _vm.city.show };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.required"))+" ")])],1)]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.notes')}},[_c('div',[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.notes.show},on:{"input":function ($event) {
            _vm.notes = { required: _vm.notes.required, show: $event };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.show"))+" ")]),_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.notes.required},on:{"input":function ($event) {
            _vm.notes = { required: $event, show: _vm.notes.show };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.required"))+" ")])],1)]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.use_google_maps')}},[_c('div',[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro},model:{value:(_vm.googleMaps),callback:function ($$v) {_vm.googleMaps=$$v},expression:"googleMaps"}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.enable"))+" ")])],1)]),_c('div',{staticClass:"font-bold uppercase"},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.payment_options"))+" ")]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.cash')}},[_c('div',[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro},model:{value:(_vm.paymentMethodCash),callback:function ($$v) {_vm.paymentMethodCash=$$v},expression:"paymentMethodCash"}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.enable"))+" ")])],1)]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.credit_card')}},[_c('div',[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro},model:{value:(_vm.paymentMethodCard),callback:function ($$v) {_vm.paymentMethodCard=$$v},expression:"paymentMethodCard"}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.enable"))+" ")])],1)]),_c('b-taginput',{attrs:{"field":"name","placeholder":_vm.$t('check_out_form_fields.enable')},model:{value:(_vm.customPaymentOptions),callback:function ($$v) {_vm.customPaymentOptions=$$v},expression:"customPaymentOptions"}}),_c('div',{staticClass:"font-bold uppercase mt-4"},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.pickup_and_delivery"))+" ")]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.pickup')}},[_c('div',[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.pickup.available},on:{"input":function ($event) {
            _vm.pickup = { available: $event };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.enable"))+" ")])],1)]),_c('b-field',{attrs:{"label":_vm.$t('check_out_form_fields.delivery')}},[_c('div',{staticClass:"flex"},[_c('b-checkbox',{attrs:{"disabled":!_vm.isPro,"value":_vm.delivery.available},on:{"input":function ($event) {
            _vm.delivery = { available: $event, cost: _vm.delivery.cost };
          }}},[_vm._v(" "+_vm._s(_vm.$t("check_out_form_fields.enable"))+" ")]),_c('b-input',{staticClass:"the200Width",attrs:{"placeholder":_vm.$t('check_out_form_fields.delivery_cost'),"type":"number","min":"0","step":"0.01","disabled":!_vm.isPro,"value":_vm.delivery.cost},on:{"input":_vm.handleSetDelivery}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }