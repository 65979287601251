<template>
  <div>
    <div class="font-bold uppercase mb-3">
      {{ $t("variants_config.variant") }}
    </div>
    <variants-option
      v-for="(productOption, index) in productOptions"
      :key="`product-option-${index}`"
      :index="index"
      :option="productOption"
    />
    <div class="mt-3">
      <b-button @click="addOption">
        {{ $t("variants_config.add_variant") }}
        <b-icon name="plus" class="ml-3" />
      </b-button>
    </div>
  </div>
</template>

<script>
import VariantsOption from "./VariantsOption";
import BIcon from "@/components/BIcon";
import * as mutations from "@/store/modules/builder/mutation-types";

export default {
  name: "VariantsConfig",

  components: {
    BIcon,
    VariantsOption
  },

  computed: {
    productOptions() {
      return this.$store.state.builder.productOptions;
    }
  },

  methods: {
    addOption() {
      this.$store.commit(`builder/${mutations.ADD_PRODUCT_OPTION}`);
    }
  }
};
</script>
