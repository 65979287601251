<template>
  <div class="pb-4">
    <div class="mb-3 font-bold">
      {{ $t("branches_config.branches_locations") }}
    </div>
    <div class="mb-3">
      <b-checkbox
        :value="branch.show"
        @input="
          $event => {
            branch = { required: branch.required, show: $event };
          }
        "
      >
        {{ $t("branches_config.show") }}
      </b-checkbox>
      <b-checkbox
        :value="branch.required"
        @input="
          $event => {
            branch = { required: $event, show: branch.show };
          }
        "
      >
        {{ $t("branches_config.required") }}
      </b-checkbox>
    </div>
    <div
      v-for="(branchItem, index) in branches"
      :key="`branch-${index}`"
      class="mb-3"
    >
      <b-field v-if="index === currentIndex && editMode">
        <b-input
          v-model="branchName"
          expanded
          placeholder="Main branch"
        ></b-input>
        <b-button :disabled="!branchName" class="ml-3" @click="saveBranch">
          {{ $t("branches_config.save") }}
        </b-button>
      </b-field>
      <b-field v-else>
        <b-input disabled expanded :value="branchItem.name"></b-input>
        <b-button class="ml-3" @click="selectBranch(branchItem, index)">
          <b-icon name="edit"></b-icon>
        </b-button>
        <b-button class="ml-3" @click="deleteBranch(index)">
          <b-icon name="trash-alt"></b-icon>
        </b-button>
      </b-field>
    </div>
    <b-field v-if="!branches.length || !editMode">
      <b-input
        v-model="branchName"
        expanded
        :placeholder="$t('branches_config.branch_name')"
      ></b-input>
      <b-button :disabled="!branchName" class="ml-3" @click="saveBranch">
        {{ $t("branches_config.save") }}
      </b-button>
    </b-field>
  </div>
</template>

<script>
import BIcon from "@/components/BIcon";
import * as mutation from "@/store/modules/builder/mutation-types";

export default {
  name: "BranchesConfig",

  components: {
    BIcon
  },

  data() {
    return {
      editMode: false,
      currentIndex: -1,
      branchName: null
    };
  },

  computed: {
    branch: {
      get() {
        return this.$store.state.builder.checkout.form.branch;
      },
      set(val) {
        this.$store.commit(`builder/${mutation.SET_CHECKOUT_FORM_BRANCH}`, val);
      }
    },
    branches() {
      return this.$store.state.builder.checkout.branches;
    }
  },

  methods: {
    selectBranch(branch, index) {
      this.editMode = true;
      this.currentIndex = index;
      this.branchName = branch.name;
    },
    saveBranch() {
      if (this.editMode) {
        this.updateBranch(this.currentIndex);
      } else {
        this.addBranch();
      }
      this.reset();
    },
    addBranch() {
      this.$store.commit(`builder/${mutation.SET_CHECKOUT_ADD_BRANCH}`, {
        name: this.branchName
      });
    },
    updateBranch(index) {
      this.$store.commit(`builder/${mutation.SET_CHECKOUT_UPDATE_BRANCH}`, {
        index: index,
        name: this.branchName
      });
    },
    deleteBranch(index) {
      this.$store.commit(
        `builder/${mutation.SET_CHECKOUT_REMOVE_BRANCH}`,
        index
      );
    },
    reset() {
      this.editMode = false;
      this.currentIndex = -1;
      this.branchName = null;
    }
  }
};
</script>
