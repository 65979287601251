<template>
  <div>
    <footer
      v-if="branded || showFooter"
      class="bg-white"
      :style="{
        backgroundColor: backgroundColor,
        color: textColor
      }"
    >
      <div class="container mx-auto px-4 py-6 text-center">
        <div v-if="showFooter">
          <p>
            {{ description }}
          </p>
          <div v-if="content" class="py-4">
            <markup-content :content="content" />
          </div>
          <router-link v-if="pivacyPolicy" to="privacy-policy" class="mt-3">
            {{ $t("privacy_policy") }}
          </router-link>
          <div class="flex justify-center w-full">
            <div
              v-for="social in socials"
              :key="social.socialName"
              class="mx-2"
            >
              <a :href="social.url" target="_blank">
                <img
                  class="social-icon"
                  :src="social.imgSrc"
                  :alt="social.socialName"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div v-if="branded" class="bg-white">
      <div class="container mx-auto px-4 py-6 text-center">
        <p>
          {{ $t("made_with") }}
          <strong>
            <a href="https://getbiew.com/">Biew</a>
          </strong>
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped>
img.social-icon {
  width: 24px;
  height: 24px;
}
</style>
<script>
import MarkupContent from "@/components/MarkupContent";
import * as imgSources from "@/assets/images/socials";

const moduleName = "builder";

const toNamedObject = ([socialName, socialCfg]) => {
  return {
    socialName,
    imgSrc: imgSources.default[socialName],
    ...socialCfg
  };
};
const byShowProp = social => social.show;

export default {
  name: "TheFooter",

  components: {
    MarkupContent
  },

  props: {
    branded: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    config() {
      return this.$store.state[moduleName].footer;
    },
    description() {
      return this.config.description;
    },
    content() {
      return this.config.content;
    },
    pivacyPolicy() {
      return this.$store.state[moduleName].privacyPolicy;
    },
    showFooter() {
      return this.config.show;
    },
    backgroundColor() {
      return this.$store.state[moduleName].footer.backgroundColor;
    },
    textColor() {
      return this.$store.state[moduleName].footer.textColor;
    },
    socials() {
      const socials = this.$store.state[moduleName].footer.socials;
      const mappedSocials = Object.entries(socials)
        .map(toNamedObject)
        .filter(byShowProp);
      return mappedSocials;
    }
  }
};
</script>
