<template>
  <div class="relative">
    <div
      v-show="!isPro"
      class="feature-block absolute inset-0 z-10 -mx-6 -my-6 border-gray-900"
    >
      <div class="w-full h-full relative">
        <div class="sticky top-0 px-4 w-full flex">
          <pro-feature-badge />
        </div>
      </div>
    </div>
    <whats-app-checkout />
    <div class="border-t border-gray-300 my-4"></div>
    <checkout-form-fields />
    <div class="border-t border-gray-300 my-6"></div>
    <branches-config />
    <div class="border-t border-gray-300 my-6"></div>
    <variants-config />
  </div>
</template>

<script>
import BranchesConfig from "./BranchesConfig";
import CheckoutFormFields from "./CheckoutFormFields";
import WhatsAppCheckout from "./WhatsAppCheckout";
import VariantsConfig from "./VariantsConfig";
import ProFeatureBadge from "@/components/ProFeatureBadge";

export default {
  name: "BuilderCheckout",

  components: {
    BranchesConfig,
    CheckoutFormFields,
    ProFeatureBadge,
    WhatsAppCheckout,
    VariantsConfig
  },

  computed: {
    isPro() {
      return this.$store.getters["builder/isPro"];
    }
  }
};
</script>

<style lang="scss" scoped>
.feature-block {
  background-color: rgba(224, 221, 221, 0.6);
}
</style>
