<template>
  <vue-editor
    :value="value"
    :editor-toolbar="customToolbar"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "TextEditor",

  components: {
    VueEditor
  },

  props: {
    value: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      customToolbar: [["bold", "italic", "underline"], ["color"], ["link"]]
    };
  }
};
</script>

<style lang="scss">
.ql-editor {
  img {
    display: inline;
  }
}
</style>
