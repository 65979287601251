<template>
  <span
    class="text-white backgroundStyle font-bold inline-flex items-center justify-center bg-blue-500 rounded-full"
    :class="[baseClass, classContainer]"
  >
    <img :class="imgClass" src="@/assets/icons/premium.svg" />
    <slot></slot>
  </span>
</template>
<style lang="scss" scoped>
.backgroundStyle {
  background: #08587a;
}

.imgSmall {
  height: 18px;
  width: 18px;
}
.imgDefault {
  height: 20px;
  width: 20px;
}
</style>
<script>
export default {
  props: {
    size: {
      type: String,
      default: "default",
      validator: function(value) {
        return ["small", "default"].includes(value);
      }
    },
    classContainer: {
      type: String,
      default: ""
    }
  },
  computed: {
    baseClass() {
      if (this.size === "small") {
        return "text-xs py-1 px-2";
      }
      return "py-2 px-4";
    },
    imgClass() {
      if (this.size === "small") {
        return "imgSmall";
      }
      return "imgDefault";
    }
  }
};
</script>
