<template>
  <b-modal
    :active="true"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    @close="$emit('close')"
  >
    <div class="modal-card modal-container">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t("custom_domain_modal.set_custom_domain") }}
        </p>
      </header>
      <section class="modal-card-body">
        <div class="flex flex-col">
          <div id="id-div-biew-domain">
            <div class="flex relative justify-center w-full">
              <div class="flex form-container w-3/4">
                <div class="radio-container">
                  <b-radio
                    v-model="domainType"
                    name="domainType"
                    native-value="biew-domain"
                  >
                  </b-radio>
                </div>
                <div class="flex flex-col w-full">
                  <b-field
                    :label="$t('builder.subdomain')"
                    :type="subdomainInputType"
                  >
                    <div class="flex">
                      <b-input
                        id="input-id-custom-domain"
                        v-model="editableSubdomain"
                        placeholder="mywebsite"
                        expanded
                        :disabled="disableEditBiewDomain"
                        class="w-full custom-input"
                      ></b-input>
                      <p class="control">
                        <span
                          class="button custom-button"
                          :disabled="disableEditBiewDomain"
                        >
                          {{ subfixDomain }}
                        </span>
                      </p>
                    </div>
                  </b-field>
                  <p
                    v-if="subdomainInputMessage"
                    class="help"
                    :class="[subdomainInputType]"
                  >
                    {{ subdomainInputMessage }}
                  </p>
                  <span
                    :class="disableEditBiewDomain ? 'has-text-grey-light' : ''"
                  >
                    {{ $t("allowed_modify_subdomain") }}
                  </span>
                  <div>
                    <b-button
                      class="mt-2"
                      type="is-info"
                      :disabled="disableEditBiewDomain"
                      :loading="checkingAvailability"
                      @click="checkAvailability"
                    >
                      {{ $t("builder.check_availability") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="div-id-divider" class="my-3"></div>
          <div
            id="id-div-custom-domain-form"
            class="relative flex justify-center"
          >
            <div class="flex w-3/4">
              <div class="radio-container">
                <b-radio
                  v-model="domainType"
                  name="domainType"
                  native-value="custom-domain"
                >
                </b-radio>
              </div>
              <div class="custom-domain-container w-full">
                <b-field label="Your custom domain">
                  <b-input
                    v-model="domain"
                    type="text"
                    :placeholder="$t('custom_domain_modal.website_example')"
                    required
                    class="w-full"
                    :disabled="!showButtons"
                  >
                  </b-input>
                </b-field>
                <div class="flex justify-center items-center">
                  <b-button
                    v-show="showButtons"
                    class="button is-light w-full"
                    :disabled="updatingDomain"
                    @click="$emit('close')"
                  >
                    {{ $t("custom_domain_modal.cancel") }}
                  </b-button>
                  <b-button
                    v-show="showButtons"
                    class="button is-primary ml-4 w-full"
                    :loading="updatingDomain"
                    :disabled="domain.trim() === ''"
                    @click="save"
                  >
                    {{ $t("custom_domain_modal.save") }}
                  </b-button>
                </div>
                <div
                  v-show="showInstructions"
                  class="border-t border-gray-300 mt-4 pt-2"
                >
                  <div class="font-bold text-lg">
                    {{ $t("custom_domain_modal.instructions") }}
                  </div>
                  <div class="font-bold mt-2">
                    {{ $t("custom_domain_modal.instructions2") }}
                  </div>
                  <div>
                    {{ $t("custom_domain_modal.instructions3") }}
                  </div>
                  <div class="mt-2 p-2 bg-gray-200 rounded-lg font-bold">
                    {{ $t("custom_domain_modal.instructions4") }}
                  </div>
                  <div class="mt-4">
                    {{ $t("custom_domain_modal.instructions5") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>
<style lang="scss" scoped>
.modal-container {
  width: 600px;
  border-radius: 5px;
}
.custom-button {
  background: rgba(225, 230, 255, 1);
  cursor: default;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  &:hover {
    background: rgba(225, 230, 255, 1);
    border-color: inherit;
  }
}
.custom-input > input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
<script>
import { mapGetters } from "vuex";
import { CUSTOM_DOMAIN, BIEW_DOMAIN } from "@/constants/action-types";
import * as Sentry from "@sentry/browser";
import { seconds } from "@/utils/functions";
export default {
  name: "CustomDomainModal",

  data() {
    return {
      editMode: false,
      domain: this.$store.state.builder.domain,
      subdomainInputMessage: "",
      subdomainInputType: "",
      domainType: this.isNotSubdomain ? CUSTOM_DOMAIN : BIEW_DOMAIN,
      updatingDomain: false,
      checkingAvailability: false
    };
  },
  computed: {
    ...mapGetters("builder", ["subfixDomain"]),
    showInstructions() {
      return this.isNotSubdomain && this.domainType === CUSTOM_DOMAIN;
    },
    disableEditBiewDomain() {
      return this.domainType === CUSTOM_DOMAIN || this.isNotSubdomain;
    },
    disableCustomDomain() {
      return !this.editMode || this.domainType === BIEW_DOMAIN;
    },
    stateDomain() {
      return this.$store.state.builder.domain;
    },
    isNotSubdomain() {
      return (
        !this.domain.endsWith(".getbiew.com") &&
        !this.domain.endsWith(".biew.site")
      );
    },
    showButtons() {
      return this.domainType === CUSTOM_DOMAIN;
    },
    editableSubdomain: {
      get() {
        return this.$store.state.builder.subdomain;
      },
      set(val) {
        this.$store.commit("builder/SET_SITE_SUBDOMAIN", val);
      }
    }
  },

  watch: {
    domainType(val) {
      if (val === BIEW_DOMAIN) {
        this.editMode = false;
      }
    }
  },

  methods: {
    async save() {
      try {
        const domain =
          this.domainType === CUSTOM_DOMAIN
            ? this.domain
            : this.editableSubdomain;
        this.updatingDomain = true;
        await this.$store.dispatch("builder/updateCustomDomain", {
          domain
        });
        this.$buefy.notification.open({
          message: this.$t("success_messages.updating_custom_domain"),
          type: "is-success",
          duration: seconds(4)
        });
      } catch (error) {
        Sentry.captureException(error);
        this.$buefy.notification.open({
          message: this.$t("error_messages.updating_custom_domain"),
          type: "is-danger"
        });
        this.domain = this.stateDomain;
      } finally {
        this.updatingDomain = false;
        this.editMode = false;
      }
    },
    async checkAvailability() {
      this.subdomainInputMessage = "";
      try {
        this.checkingAvailability = true;
        const res = await this.$store.dispatch(
          "builder/assignDomain",
          this.editableSubdomain
        );
        this.subdomainInputType = "is-success";
        this.subdomainInputMessage = res?.message || "";
      } catch (error) {
        Sentry.captureException(error);
        this.subdomainInputType = "is-danger";
        if (error?.response?.data?.message) {
          this.subdomainInputMessage = error.response.data.message;
        } else {
          this.subdomainInputMessage = "An error ocurred, try again or refresh";
        }
      } finally {
        this.checkingAvailability = false;
      }
    },
    cancel() {
      this.domain = this.stateDomain;
      this.editMode = false;
    },
    editDomain() {
      this.editMode = true;
    }
  }
};
</script>
