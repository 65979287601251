<template>
  <browser-card
    id="browser-card"
    class="min-h-screen w-full bg-gray-100 relative"
    :style="{
      backgroundColor: theme.backgroundColor
    }"
  >
    <b-navigation
      :background-color="theme.navbarColor"
      :site-name="siteName"
      :logo="logo"
      :fixed-nav="fixedNav"
    />
    <div class="min-h-full">
      <router-view></router-view>
    </div>
    <the-footer class="mt-3" :branded="showBrandedFooter" />
    <shopping-cart v-show="showShoppingCart" id="shopping-cart" />
    <product-options-card />
  </browser-card>
</template>

<script>
import BNavigation from "@/components/BNavigation";
import BrowserCard from "@/components/BrowserCard";
import ProductOptionsCard from "@/components/ProductOptionsCard";
import ShoppingCart from "@/components/ShoppingCart/ShoppingCart";
import TheFooter from "@/components/TheFooter";
import { mapState } from "vuex";

export default {
  name: "BuilderPreview",

  components: {
    BNavigation,
    BrowserCard,
    ProductOptionsCard,
    ShoppingCart,
    TheFooter
  },

  computed: {
    ...mapState("builder", ["logo", "siteName"]),
    showShoppingCart() {
      return this.$store.getters["builder/isShoppingCartEnabled"];
    },
    isPro() {
      return this.$store.state.builder.isPro;
    },
    isDemo() {
      return this.$store.state.builder.demo;
    },
    showBranding() {
      return this.$store.state.builder.showBranding ? true : false;
    },
    showBrandedFooter() {
      return ((!this.isPro && !this.isDemo) ||
        (this.showBranding && !this.isDemo)) &&
        !this.isLoading
        ? true
        : false;
    },
    theme() {
      return this.$store.state.builder.theme;
    },
    fixedNav() {
      return this.$store.state.builder.fixedNav;
    }
  },

  mounted() {
    const browserCard = document.querySelector("#browser-card");
    const shoppingCart = document.querySelector("#shopping-cart");
    shoppingCart.style.width = `${browserCard.offsetWidth}px`;
  }
};
</script>
