<template>
  <div>
    <div v-if="disabled">
      <div class="card-header bg-gray-100 flex items-center" role="button">
        <p class="card-header-title text-gray-500">
          {{ name }}
        </p>
        <div v-if="isPro" class="mr-1">
          <pro-feature-badge v-if="isPro" />
        </div>
      </div>
    </div>
    <b-collapse v-else class="card" animation="slide" :open="false">
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title">
            {{ name }}
          </p>
          <a class="card-header-icon">
            <b-icon :name="props.open ? 'caret-up' : 'caret-down'"> </b-icon>
          </a>
        </div>
      </template>
      <div class="p-4">
        <slot />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import BIcon from "@/components/BIcon";
import ProFeatureBadge from "@/components/ProFeatureBadge";

export default {
  name: "SectionCollaps",

  components: {
    BIcon,
    ProFeatureBadge
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isPro: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    }
  }
};
</script>
